<template>
  <div id="selectpet-breed" class="mt-2">
    <!-- <b-spinner label="Spinning"></b-spinner> -->
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>WHAT'S THE BREED?
          </h3>
          <span v-if="button" class="pet-select-breed-error-text text-danger"
            >*Please select a breed</span
          >
        </center>
        <label class="wrap my-5 col-md-12">
          <select
            name=""
            class="form-control noBorder"
            id=""
            v-model="selected_breed"
          >
            <option value="">--Select Breed--</option>
            <option :value="breed" v-for="breed in breeds">{{
              breed.breed_name
            }}</option>
          </select>
        </label>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SelectBreed",
  props: ["species"],
  data() {
    return {
      breeds: [],
      selected_breed: "",
      button: false,
      loader: true,
    };
  },
  created() {
    // if(this.petoccasions.length < 1){
    this.getAllBreeds();
    // }
  },
  methods: {
    getAllBreeds() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.user.api_token;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/getBreeds";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          id: this.species.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.breeds = data.breeds;
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      if (this.selected_breed == "") {
        this.button = true;
      } else {
        this.$emit("breedToGender", this.selected_breed);
      }
    },
    prev() {
      this.$emit("breedTospecies");
    },
  },
};
</script>

<style>
.pet-select-breed-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
