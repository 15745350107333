<template>
  <div id="select-image" class="mt-2">
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>UPLOAD PET IMAGE
          </h3>
        </center>
        <div class="modal-inner">
          <center>
            <div class="row my-4 mx-0">
              <input
                type="file"
                v-on:change="onImageChange"
                class="form-control"
              />
            </div>
          </center>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "AddImage",
  props: ["menu"],
  data() {
    return {
      image: null,
      loader: false,
    };
  },
  created() {},
  methods: {
    saveImage() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var user_id = userdata_array.user.id;
      var fetch_url = process.env.VUE_APP_URL + "customer/pet/addImage";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.gender,
          user_id: user_id,
          friend_name: this.friend_name,
          gender: this.gender,
          mobile: this.mobile,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.friends = data.friends;
          this.$bvModal.hide("addfriend-modal");
          this.friend_name = null;
          this.getFriends();
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
        vm.preview_image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    next() {
      this.loader = true;
      this.$store.state.pet_image = this.image;
      this.$emit("ImageToPrice", this.image);
    },
    prev() {
      this.$emit("ImageToOccasion");
    },
  },
};
</script>
