import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  state: {
    //Gift cards
    keyword: null,
    products: [],
    page: 1,
    category: null,
    totalPages: null,
    single_product: [],
    //Wizard
    wizardProducts_items: [],
    wizardProducts_suggested_items: [],
    user_contact: null,
    user_contact_name: null,
    clicked_wizarddata: null,
    occasion_id: null,
    characterTrait: null,
    request_body: null,
    //Pet Wizard
    pet_wizardProducts_items: [],
    pet_wizardProducts_suggested_items: [],
    wizard_data: null,
    pet_id: null,
    pet_name: null,
    pet_image: null,
    wizard_type: null,
    menu: null,
    wizData: null,
    browsemenus:[],
    browse_id: null,
  },
  modules,
  getters
})

export default store
