<template>
  <div id="select-continue" class="mt-2">
    <!-- <b-spinner v-if="loader"></b-spinner> -->
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <!-- <h3 class="mb-3" for=""><strong class="text-danger my-3"></strong>WHAT'S THE PERSON'S PERSONA?</h3> -->
        </center>
        <div class="modal-inner  d-flex border justify-content-center bg-light">
          <div class="row">
            <h4 class="my-5 mx-5">
              {{ name }} was already saved in our system
            </h4>
          </div>
        </div>
        <!-- <span v-if="button" class="text-danger">*Please select Persona</span> -->
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "selectContinue",
  props: ["petData", "name"],
  data() {
    return {
      loader: false,
    };
  },
  created() {},
  methods: {
    next() {
      this.$emit("continueToOccasion", true);
    },
    prev() {
      this.$emit("continueToPet");
    },
  },
};
</script>
