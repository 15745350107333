<template>
  <div id="select-occasion" class="mt-2">
    <!-- <b-spinner label="Spinning"></b-spinner> -->
    <b-overlay :show="loader" rounded="sm">
      <div class="container mb-3 ">
        <center>
          <h3 for="">
            <strong class="text-danger my-3"></strong>WHAT'S THE OCCASION?
          </h3>
          <span v-if="button" class="select-occasion-error-text text-danger"
            >*Please select an Occasion</span
          >
        </center>
        <label class="wrap mt-5 mb-1 col-md-12">
          <select
            name=""
            class="form-control noBorder"
            id=""
            v-model="occasion"
          >
            <option value="">--Select Occasion--</option>
            <option :value="occasion" v-for="occasion in occasions">{{
              occasion.occasion_name
            }}</option>
          </select>
        </label>

        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button
                class="btn btn-warning col-md-6"
                @click="occasionToCategory"
              >
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "SelectOccasion",
  props: ["menu", "occasionToCategory"],
  data() {
    return {
      occasions: [],
      occasion: "",
      button: false,
      loader: true,
    };
  },
  created() {
    if (this.occasions.length < 1) {
      this.getOccasions();
    }
  },
  methods: {
    getOccasions() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      if (userdata_array) {
        var api_token = userdata_array.user.api_token;
      }
      const controller = new AbortController();

      // 5 second timeout:
      const timeoutId = setTimeout(() => controller.abort(), 3000);
      var fetch_url = "";

      if (this.menu?.id)
        fetch_url =
          process.env.VUE_APP_URL +
          `customer/occasion/getOccasionsByMenu/${this.menu.id}`;
      else
        fetch_url = process.env.VUE_APP_URL + "customer/occasion/getOccasions";

      fetch(
        fetch_url,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            // Authorization: "Bearer " + api_token,
          },
        },
        { signal: controller.signal }
      )
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.occasions = data.occasions;
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    next() {
      if (this.occasion == "") {
        this.button = true;
      }
      // else if(this.occasion.special_occasion == 'True') {
      //     this.$emit('ocasionToPrice', this.occasion);
      // }
      else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        if (userdata_array) {
          this.$emit("ocasionToAddfriend", this.occasion);
        } else {
          this.$emit("ocasionToAgeRange", this.occasion);
        }
      }
    },
    prev() {},
  },
};
</script>

<style>
.select-occasion-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
