<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
    import "@/assets/site/css/govava.min.css"
    import "@/assets/site/css/styles-l.min.css"
    import "@/assets/site/css/config_default.css"
    import "@/assets/site/css/custom.css"

    export default {
        name: 'App',
    }
</script>