<template>
  <div id="select-name" class="mt-2">
    <div class="container mb-3 ">
      <center>
        <h3 for="">
          <strong class="text-danger my-3"></strong>ENTER NAME OF THE PET
        </h3>
        <span v-if="button" class="pet-select-name-error-text text-danger"
          >*Please enter pet's name</span
        >
      </center>
      <label class="wrap my-5 col-md-12">
        <input
          type="text"
          v-model="name"
          class="form-control noBorder"
          placeholder="Enter the Pet's name (max 25 characters)"
          name=""
          id=""
          maxlength="25"
        />
      </label>

      <div class="mt-3 mb-3" slot="modal-footer">
        <div class="row d-flex justify-content-between">
          <div class="col-md-4 ">
            <button class="btn btn-warning col-md-6" @click="prev">
              Previous
            </button>
          </div>
          <div class="col-md-4">
            <button class="btn btn-danger float-right col-md-6" @click="next">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnterName",
  data() {
    return {
      name: "",
      button: false,
    };
  },
  created() {
    // this.getOccasions();
  },
  methods: {
    next() {
      if (this.name == "") {
        this.button = true;
      } else {
        this.$emit("nameToOccasion", this.name);
      }
    },
    prev() {
      this.$emit("nameToAge");
    },
  },
};
</script>

<style>
.pet-select-name-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
