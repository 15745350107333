<template>
  <div id="select-weight" class="mt-2">
    <div class="container mb-3 ">
      <center>
        <h3 for="">
          <strong class="text-danger my-3"></strong>ENTER WEIGHT OF THE PET
        </h3>
        <span v-if="button" class="pet-select-weight-error-text text-danger"
          >*Please enter pet's weight</span
        >
      </center>
      <label class="wrap my-5 col-md-12">
        <input
          type="text"
          v-model="weight"
          class="form-control noBorder"
          placeholder="Enter the Pet's weight"
          name=""
          id=""
        />
      </label>
      <div class="mt-3 mb-3" slot="modal-footer">
        <div class="row d-flex justify-content-between">
          <div class="col-md-4 ">
            <button class="btn btn-warning col-md-6" @click="prev">
              Previous
            </button>
          </div>
          <div class="col-md-4">
            <button class="btn btn-danger float-right col-md-6" @click="next">
              Next
            </button>
            <!-- <button class="btn btn-outline-danger float-right col-md-2 mx-2" @click="skip">skip <i
                        class="fa fa-fast-forward" aria-hidden="true"></i></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectWeight",
  data() {
    return {
      weight: "",
      button: false,
    };
  },
  created() {
    // this.getOccasions();
  },
  methods: {
    next() {
      // if (this.weight == '') {
      //     this.button = true;
      // } else {
      this.$emit("weightToName", this.weight);
      // }
    },
    prev() {
      this.$emit("weightToAge");
    },
  },
};
</script>

<style>
.pet-select-weight-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
