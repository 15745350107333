<template>
  <div id="select-pet" class="mt-2">
    <div class="container mb-3 ">
      <center>
        <h3 class="mb-3" for="">
          <strong class="text-danger my-3"></strong>SELECT YOUR PET
        </h3>
        <span v-if="button" class="pet-select-name-error-text text-danger"
          >*Please select pet</span
        >
      </center>
      <!-- <div class="col-md-4 input-group input-group-sm"> -->
      <!-- <button class="btn btn-danger float-right col-md-6" @click="next">Next</button> -->
      <!-- <div class="input-group input-group-sm"> -->
      <!-- <div class="input-group-append" @click="getFriends">
                                    <div class="btn btn-navbar input-search">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </div> -->
      <!-- <button class="btn btn-danger float-right col-md-2" @click="skip">skip <i class="fa fa-fast-forward" aria-hidden="true"></i></button> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div>  -->
      <div class="modal-inner" style="min-height: 160px !important;">
        <div class="row mt-3">
          <vue-select-image
            :h="'75px'"
            :w="'100px'"
            useLabel
            :dataImages="dataImages"
            @onselectimage="onSelectImage"
          >
          </vue-select-image>
        </div>
      </div>
      <div class="mt-5 mb-" slot="modal-footer">
        <button class="btn btn-danger float-right col-md-2" @click="next">
          Next
        </button>
        <button
          class="btn btn-outline-danger float-right col-md-2 mx-2"
          @click="skip"
        >
          Add PET <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");
export default {
  name: "SelectPet",
  components: {
    VueSelectImage,
  },
  props: ["pets"],
  data() {
    return {
      traits: [],
      caricature_trait: null,
      loader: false,
      button: false,
      start_age: null,
      end_age: null,
      dataImages: null,
      // onSelectImage: [],
    };
  },
  created() {
    // if(this.traits.length == 0){
    this.getImageArray(this.pets);
    // }
  },
  methods: {
    getImageArray(pets) {
      var img_array = new Array();

      pets.forEach(function(pet) {
        if (pet.pet_image != null) {
          img_array.push({
            id: pet.id,
            src: pet.pet_image,
            alt: pet.petwizardDetails.name,
            data: pet.petwizardDetails,
          });
        } else {
          img_array.push({
            id: pet.id,
            src: pet.petwizardDetails.type.pet_icon,
            alt: pet.petwizardDetails.name,
            data: pet.petwizardDetails,
          });
        }
      });
      this.dataImages = img_array;
    },
    onSelectImage(image) {
      this.pet = image;
    },
    next() {
      if (!this.pet) {
        this.button = true;
      } else {
        this.$store.state.pet_image = this.pet.src;
        this.$emit("petToContinue", this.pet.data);
      }
      // if(this.pet.pet_image){
      // } else {
      //     this.$store.state.pet_image = this.pet.data.type.pet_icon;
      // }
    },
    prev() {
      this.$emit("traitToAgeRange");
    },
    skip() {
      this.$emit("petToSpecies");
    },
  },
};
</script>
