<template>
  <div id="select-style" class="mt-2">
    <!-- <b-spinner v-if="loader"></b-spinner> -->
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3 class="mb-3" for="">
            <strong class="text-danger my-3"></strong>WHAT IS THEIR ACTIVE
            LIFESTYLE?
          </h3>
          <span v-if="button" class="select-style-error-text text-danger"
            >*Please select a style</span
          >
        </center>
        <div class="modal-inner">
          <center>
            <p class="text-primary font-weight-bold mb-3">
              Please choose 1 to 3 Active Lifestyle
            </p>
          </center>
          <div class="row">
            <!-- <div v-if="style.type == 'active_lifestyle'" class="col-md-3" v-for="style in styles">
                        <center>
                            <img  :src="style.icon_style" class="avatar-img" alt=""><br>
                            <input type="radio" name="image" :value="style" v-model="active_lifestyle">
                        </center>
                        </div> -->
            <div class="persona-wrapper">
              <vue-select-image
                :dataImages="dataImages"
                :is-multiple="true"
                useLabel
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                @onselectmultipleimage="onSelectMultipleImage"
              >
              </vue-select-image>
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");
export default {
  name: "SelectStyle",
  components: { VueSelectImage },
  props: ["menu", "gender"],
  data() {
    return {
      styles: [],
      active_lifestyle: [],
      loader: false,
      button: false,
      start_age: null,
      end_age: null,
      dataImages: null,
    };
  },
  created() {
    // if(this.styles.length == 0){
    this.getPersonas();
    // }
  },
  methods: {
    getPersonas() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      // var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.gender,
          type: this.menu.label,
          ch_type: "active_lifestyle",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.styles = data.caricatures;
          this.getImageArray(this.styles);
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getImageArray(styles) {
      var img_array = new Array();

      styles.forEach(function(style) {
        img_array.push({
          id: style.id,
          src: style.icon_style,
          alt: style.name,
        });
      });
      this.dataImages = img_array;
    },
    onSelectMultipleImage(images) {
      // console.log(images);
      this.active_lifestyle = images;
    },
    next() {
      if (this.active_lifestyle.length < 1) {
        this.button = true;
      } else if (this.menu == "under_fifty") {
        this.loader = true;
        this.$emit("underFifty", this.active_lifestyle);
      } else {
        this.$emit("styleToPrice", this.active_lifestyle);
      }
    },
    prev() {
      this.$emit("styleToPersona");
    },
  },
};
</script>

<style>
.persona-wrapper label {
  text-transform: capitalize;
}

.select-style-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
