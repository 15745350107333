import Vue from 'vue'
import App from './App.vue'
import router from './router/routerSite.js'
import store from './store'
import Vuex from 'vuex'
Vue.use(Vuex)
window.$ = window.jQuery = require('jquery');

import 'bootstrap'

//bootstrapvue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue);
import 'bootstrap-vue/dist/bootstrap-vue.css'
//end
//Sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import 'sweetalert2/dist/sweetalert2.min.css';
//End of sweet alert
//Toggle Button
import ToggleButton from 'vue-js-toggle-button'

Vue.use(ToggleButton)
//End of toggle buttion

Vue.config.productionTip = false;
router.beforeEach(async (to, from, next) => {
    // Redirect to Dashboard if logged in
    if (localStorage.getItem('userData') !== null) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        if (userData.customer) {
            window.location.href = "home";
        }
    }
    // ##Redirect to Dashboard if logged in
    document.title = process.env.VUE_APP_NAME + ' : ' + to.meta.title;
    next();
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app')