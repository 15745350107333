<template>
  <div id="select-occasion" class="mt-2">
    <!-- <b-spinner v-if="loader"></b-spinner> -->
    <b-overlay :show="loader">
      <div class="container mb-3 ">
        <center>
          <h3 class="mb-3" for="">
            <strong class="text-danger my-3"></strong>WHAT IS THEIR PERSONA?
          </h3>
          <span v-if="button" class="select-persona-error-text text-danger"
            >*Please select Persona</span
          >
        </center>
        <center>
          <p class="text-primary font-weight-bold mb-3">
            Please choose 1 to 3 Persona
          </p>
        </center>
        <div class="modal-inner">
          <div class="row">
            <!-- <div v-if="persona.type == 'persona'" class="col-md-3" v-for="persona in personas">
                        <center>
                            <img :src="persona.icon_persona" class="avatar-img" alt=""><br>
                            <input type="radio" name="image" :value="persona" v-model="caricature_persona">
                        </center>
                    </div> -->

            <div class="persona-wrapper">
              <vue-select-image
                :dataImages="dataImages"
                :is-multiple="true"
                :h="'65px'"
                :w="'100px'"
                :limit="3"
                useLabel
                @onselectmultipleimage="onSelectMultipleImage"
              >
              </vue-select-image>
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3" slot="modal-footer">
          <div class="row d-flex justify-content-between">
            <div class="col-md-4 ">
              <button class="btn btn-warning col-md-6" @click="prev">
                Previous
              </button>
            </div>
            <div class="col-md-4">
              <button class="btn btn-danger float-right col-md-6" @click="next">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <img
            src="@/assets/site/images/Govava_Wait_icon.gif"
            alt=""
            class=""
          />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import VueSelectImage from "vue-select-image";
require("vue-select-image/dist/vue-select-image.css");
export default {
  name: "SelectPersona",
  components: {
    VueSelectImage,
  },
  props: ["menu", "gender"],
  data() {
    return {
      personas: [],
      caricature_persona: [],
      loader: false,
      button: false,
      start_age: null,
      end_age: null,
      dataImages: null,
    };
  },
  created() {
    // if(this.personas.length == 0){
    this.getPersonas();
    // }
  },
  methods: {
    getPersonas() {
      this.loader = true;
      var token = localStorage.getItem("userData");
      // var userdata_array = JSON.parse(token);
      // var api_token = userdata_array.token;
      var fetch_url = process.env.VUE_APP_URL + "customer/persona/getPersonas";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + api_token,
        },
        body: JSON.stringify({
          gender: this.gender,
          type: this.menu.label,
          ch_type: "persona",
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.personas = data.caricatures;
          this.getImageArray(this.personas);
          // this.$emit('showSignupSuccess', true);
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getImageArray(personas) {
      var img_array = new Array();

      personas.forEach(function(persona) {
        img_array.push({
          id: persona.id,
          src: persona.icon_persona,
          alt: persona.name,
        });
      });
      this.dataImages = img_array;
    },
    onSelectMultipleImage(images) {
      // console.log(images);
      this.caricature_persona = images;
    },
    next() {
      if (this.caricature_persona.length < 1) {
        this.button = true;
      } else {
        this.loader = true;
        this.$emit("showPersonaToLifestyle", this.caricature_persona);
      }
    },
    prev() {
      this.$emit("personaToRelation");
    },
  },
};
</script>

<style>
.persona-wrapper label {
  text-transform: capitalize;
}

.select-persona-error-text {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
}
</style>
